<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-loading :is-open="loading || biometricsLoading" message="Please wait..." :duration="20000"
          cssClass="medium-loading-container"/>
        <ion-toast position="top" color="danger" :is-open="error" :message="errorMessage" :duration="5000"
          @didDismiss="dismissError"/>
        <ion-toast position="top" color="success" :is-open="success" :message="successMessage" :duration="5000"
          @didDismiss="dismissSuccess"/>
        <ion-card>
          <h1 class="login-title">
            <img src="/assets/logo/logo.png" alt="Loop Logo" class="loop-logo"/>
          </h1>
          <ion-card-content>
            <div v-if="code"/>
            <div v-else-if="token">
              <SecurityCode :token="token" @verifyingCode="loading=true"
                  @showSuccess="showSuccess" @showError="showError"/>
            </div>
            <div v-else-if="showPromotion">
              <div>
                Loop is better on the app
              </div>
              <ion-button @click="openAppStore" expand="block">
                Get the Loop app
              </ion-button>
            </div>
            <div v-else>
              <ion-item>
                <ion-label>
                  <ion-icon :ios="calendar" :md="calendar"/>
                  Date of Birth
                </ion-label>
              </ion-item>
              <ion-item class="input-item">
                <ion-input type="text" autocomplete="bday" inputmode="numeric" maxLength="10" v-model="dob"
                    placeholder="MM/DD/YYYY" v-cleave="{delimiter: '/', blocks: [2, 2, 4]}" enterkeyhint="next"
                    @keyup.enter="focusPhoneNumber"/>
              </ion-item>
              <ion-item class="phone-number-label">
                <ion-label>
                  <ion-icon :ios="call" :md="call"/>
                  Phone Number
                </ion-label>
              </ion-item>
              <ion-item class="input-item">
                <ion-input type="tel" autocomplete="tel" inputmode="numeric" maxLength="14" v-model="phone"
                    placeholder="(201) 555-0123" v-cleave="{delimiters: ['(', ') ', '-'], blocks: [0, 3, 3, 4]}"
                    enterkeyhint="send" @keyup.enter="verifyPhoneDOB"/>
              </ion-item>
              <ion-button @click="verifyPhoneDOB">
                Login
              </ion-button>
            </div>
          </ion-card-content>
        </ion-card>
        <div v-if="!Capacitor.isNative && !isPlatform('ios') && !isPlatform('android')" class="store-button">
          <div>
            Get the app
          </div>
          <a :href="googlePlayLink">
            <img class="google" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"/>
          </a>
          <a :href="appStoreLink">
            <img class="apple" src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"/>
          </a>
        </div>
        <a v-if="showPromotion && !token && !code" @click="showPromotion=false">
          Continue on the web
        </a>
      </div>
    </ion-content>
    <ion-footer>
      <div class="disclaimer">
        Copyright {{currentYear()}} © Medsien, Inc. All Rights Reserved.
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonFooter,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardContent,
  IonLoading,
  IonToast,
  isPlatform,
  IonIcon,
  IonButton,
} from '@ionic/vue'
import { calendar, call } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
import { AUTH_REQUEST } from '../store/actions/auth'
import {BIOMETRICS_LOGIN, BIOMETRICS_ENABLE_LOADING} from '../store/actions/biometrics'
import {mapGetters} from "vuex";
import { defineComponent } from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'
import SecurityCode from '../components/SecurityCode'
import { Mixpanel } from '@/services/mixpanel'

export default defineComponent({
  name: 'Login',
  setup () {
    return {
      calendar, call,
      Capacitor, isPlatform
    }
  },
  data () {
    return {
      loading: false,
      error: false,
      errorMessage: 'We could not verify your information. Please try again.',
      success: false,
      successMessage: '',
      dob: '',
      phone: '',
      token: '',
      code: '',
      isNativePlatform: Capacitor.isNativePlatform(),
      showPromotion: !Capacitor.isNative && (isPlatform('ios') || isPlatform('android')),
      appStoreLink: 'https://apps.apple.com/us/app/loop-by-medsien/id1595781778',
      googlePlayLink: 'https://play.google.com/store/apps/details?id=com.medsien.loop',
    }
  },
  components: {
    IonContent,
    IonFooter,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonCard,
    IonCardContent,
    IonLoading,
    IonToast,
    IonIcon,
    IonButton,
    SecurityCode,
  },
  ionViewWillEnter () {
    this.resetData()
    this.trackMixpanel('login_view_enter')
    const params = new URLSearchParams(window.location.search)
    if (params.has('t')) {
      this.loginWithParams(params)
    }
    else if(this.isNativePlatform && this.locked){
      this.loginWithBiometrics()
    }
  },
  ionViewWillLeave () {
    this.trackMixpanel('login_view_leave')
  },
  methods: {
    openAppStore () {
      let url = isPlatform('ios') ? this.appStoreLink : this.googlePlayLink
      window.open(url)
    },
    resetData () {
      this.loading = false
      this.error = false
      this.errorMessage = 'We could not verify your information. Please try again.'
      this.success = false
      this.successMessage = ''
      this.dob = ''
      this.phone = ''
      this.token = ''
      this.code = ''
      this.showPromotion = !Capacitor.isNative && (isPlatform('ios') || isPlatform('android'))
    },
    trackMixpanel (eventName, props) {
      let data = {
        dob: this.dob,
        phone: this.phone,
        token: this.token,
        code: this.code,
        platform: this.isNativePlatform ? 'mobile' : 'web',
      }
      if (props) {
        data = {...data, ...props}
      }
      Mixpanel.track(eventName, data)
    },
    loginWithBiometrics () {
      this.$store.commit(BIOMETRICS_ENABLE_LOADING)
      this.trackMixpanel('login_view_enter_biometrics')
      this.$store.dispatch(BIOMETRICS_LOGIN)
    },
    loginWithParams (params) {
      this.token = decodeURIComponent(params.get('t'))
      let data = {token: this.token, force_send: true}
      if (params.has('c')) {
        this.loading = true
        this.code = decodeURIComponent(params.get('c'))
        data['code'] = this.code
      }
      this.trackMixpanel('login_view_enter_params')
      this.$store.dispatch(
          AUTH_REQUEST, data
      ).catch(({response}) => {
        if (this.code) {
          this.code = ''
          this.loading = false
        }
        if (!response || response.status !== 401) {
          this.token = ''
        }
      })
    },
    setError (errorMessage) {
      if (errorMessage) {
        this.errorMessage = errorMessage
      }
      this.error = true
      this.loading = false
    },
    showError (errorMessage) {
      let toast = document.getElementsByTagName('ion-toast')[0]
      if (this.error && toast) {
        toast.dismiss().then(() => {
          this.setError(errorMessage)
        })
      } else {
        this.setError(errorMessage)
      }
      this.trackMixpanel('login_view_error', {errorMessage})
    },
    dismissError () {
      this.error = false
    },
    setSuccess (successMessage) {
      if (!successMessage) {return}
      this.successMessage = successMessage
      this.success = true
      this.loading = false
    },
    showSuccess (successMessage) {
      let toast = document.getElementsByTagName('ion-toast')[0]
      if (this.success && toast) {
        toast.dismiss().then(() => {
          this.setSuccess(successMessage)
        })
      } else {
        this.setSuccess(successMessage)
      }
    },
    dismissSuccess () {
      this.success = false
    },
    validatePhone () {
      if (this.phone.length != 14) {return}
      return this.phone.replace(/\D/g, '')
    },
    validateDOB () {
      if (this.dob.length != 10) {return}
      return dayjs(this.dob).format('YYYY-MM-DD')
    },
    verifyPhoneDOB () {
      let dob = this.validateDOB()
      if (!dob) {
        this.showError('Please enter a valid date of birth.')
        return
      }
      let phone = this.validatePhone()
      if (!phone) {
        this.showError('Please enter a valid phone number.')
        return
      }
      if (this.loading) {return}
      this.loading = true
      axios({
        method: 'post',
        url: '/patient_app/verify_phone_dob/',
        data: {
          dob: dob,
          mobile_phone: phone,
        },
      }).then((response) => {
        this.token = response.data.token
        this.dob = ''
        this.phone = ''
        this.loading = false
      }).catch((e) => {
        this.showError('The date of birth and/or the phone number you have entered is incorrect.')
        throw e
      })
    },
    currentYear () {
      return new Date().getFullYear()
    },
    focusPhoneNumber () {
      let input = document.getElementsByTagName('ion-input')[1]
      if (input) {
        input.setFocus()
      }
    },
  },
  watch: {
    locked (to, from) {
      if (from === true && to === false) {
        this.showError('Biometric login disabled.')
      }
      else if (from === false && to === true) {
        this.showSuccess('Biometric login enabled.')
      }
    },
    biometricsSuccess (to, from) {
      if (from === false && to === true) {
        this.showSuccess('Welcome back!')
      }
    },
  },
  computed: {
    ...mapGetters([
      'locked',
      'biometricsLoading',
      'biometricsSuccess',
    ])
  },
})
</script>

<style scoped>
#container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  min-height: 470px;
}
@media only screen and (max-height: 700px) {
  #container {
    margin-bottom: 100px;
  }
}
#container ion-card {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 700px;
  min-width: 250px;
  align-self: center;
  background: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
}
#container ion-item {
  --border-style: none;
  text-align: center;
  --background: var(--ion-color-primary-shade);
  --background-focused: var(--ion-color-primary-shade);
  --background-activated: var(--ion-color-primary-shade);
  --background-hover: var(--ion-color-primary-shade);
  --color-focused: var(--ion-color-primary-contrast);
  --color-activated: var(--ion-color-primary-contrast);
  --color-hover: var(--ion-color-primary-contrast);
  --color: var(--ion-color-primary-contrast);
  --highlight-color-focused: transparent;
}
#container .input-item {
  --background: var(--ion-color-primary);
  margin: 0 25px 0 25px;
  caret-color: var(--ion-color-primary-contrast);
}
#container ion-button {
  --padding-start: 2em;
  --padding-end: 2em;
  height: 2em;
  --border-radius: 20px;
  --background: var(--ion-color-primary-contrast);
  --background-focused: var(--ion-color-primary-contrast);
  --background-activated: var(--ion-color-primary-contrast);
  --background-hover: var(--ion-color-primary-contrast);
  --color-focused: var(--ion-color-primary-shade);
  --color-activated: var(--ion-color-primary-shade);
  --color-hover: var(--ion-color-primary-shade);
  --color: var(--ion-color-primary-shade);
  margin-top: 25px;
  margin-bottom: 7px;
}
.phone-number-label {
  margin-top: 19px;
}
.store-button {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.store-button div {
  width: 100%;
}
.store-button .google {
  width: 250px;
}
.store-button .apple {
  width: 200px;
  padding-top: 14px;
  margin-right: 40px;
}
.loop-logo {
  width: 200px;
  padding: 20px;
}
.disclaimer {
  text-align: center;
  color: var(--ion-color-medium-shade);
  font-size: .55em;
  padding-bottom: 10px;
  padding-top: 5px;
}
.footer-ios .disclaimer {
  padding-bottom: 20px;
}
.login-title {
  border-bottom: 1px solid var(--ion-color-primary-contrast);
  margin: 0;
}
</style>
