<template>
  Please check your messages for a login link. <br/>
  You should receive it in a few seconds. <br/> <br/>
  <ion-item>
    <ion-label>
      <ion-icon :ios="chatbox" :md="chatbox"/>
      Verification Code
    </ion-label>
  </ion-item>
  <ion-button v-if="initialIdle" @click="resendCode" :disabled="(!idle | resending)">
    <ion-spinner v-if="resending"/>
    <div v-else>
      <ion-icon :ios="refresh" :md="refresh"/>
      Resend
    </div>
  </ion-button>
  <div class="otp-input-container">
    <ion-input class="otp-input" autocomplete="one-time-code" inputmode="numeric" :maxLength="codeLength" v-model="code"
        :placeholder="'_'.repeat(codeLength)" enterkeyhint="send" @keyup.enter="verifyCode"/>
    <span v-for="index in codeLength" :key="index" class="otp-mask"/>
  </div>
  <ion-button @click="verifyCode">
    Submit
  </ion-button>
</template>

<script>
import {
  IonItem,
  IonInput,
  IonButton,
  IonSpinner,
  IonIcon,
  IonLabel,
} from '@ionic/vue'
import { refresh, chatbox } from 'ionicons/icons'
import { AUTH_REQUEST } from '../store/actions/auth'
import { Capacitor } from '@capacitor/core'
import { TextZoom } from '@capacitor/text-zoom'

const codeLength = 6

export default {
  name: 'SecurityCode',
  emits: ['verifyingCode', 'showError', 'showSuccess'],
  components: {
    IonItem,
    IonInput,
    IonButton,
    IonSpinner,
    IonIcon,
    IonLabel,
  },
  setup () {
    return {
      codeLength,
      refresh,
      chatbox,
    }
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      code: '',
      checkingCode: false,
      codeInterval: null,
      codeIntervalStart: null,
      resendStart: null,
      resending: false,
      resendTime: null,
      initialIdle: false,
      idle: false,
    }
  },
  created () {
    if (Capacitor.isPluginAvailable('TextZoom')) {
      TextZoom.get().then(({value}) => {
        if (value !== 1) {
          TextZoom.set({value: 1})
        }
      })
    }
    this.startCodeInterval()
  },
  methods: {
    verifyCode () {
      if (this.code.length != codeLength) {
        this.$emit('showError', 'Please enter a valid verification code.')
        return
      }
      this.checkingCode = true
      this.$emit('verifyingCode')
      let data = {token: this.token, code: this.code}
      this.$store.dispatch(
        AUTH_REQUEST, data
      ).then(() => {
        this.stopCodeInterval()
        this.code = ''
      }).catch(() => {
        this.checkingCode = false
        this.$emit('showError', 'The verification code you have entered is incorrect.')
      })
    },
    stopCodeInterval () {
      if (!this.codeInterval) {return}
      clearInterval(this.codeInterval)
      this.codeInterval = null
      this.checkingCode = false
    },
    startCodeInterval () {
      this.stopCodeInterval()
      this.codeInterval = setInterval(this.checkCode, 2000)
      this.codeIntervalStart = Date.now()
    },
    checkCode () {
      this.checkIdle()
      if (this.checkingCode) {return}
      this.checkingCode = true
      this.$store.dispatch(
        AUTH_REQUEST, {token: this.token}
      ).then(() => {
        this.stopCodeInterval()
      }).catch(() => {
        // try again in a few seconds
        this.checkingCode = false
      })
    },
    checkIdle () {
      let start = this.initialIdle ? this.resendTime : this.codeIntervalStart
      if (!start) {return}
      let idle = Date.now() - start > 2*60*1000
      if (!this.initialIdle) {
        this.initialIdle = idle
      }
      this.idle = idle
    },
    resendCode () {
      if (this.resending) {return}
      this.resending = true
      this.$store.dispatch(
        AUTH_REQUEST, {token: this.token, force_send: true}
      ).catch(() => {
        this.resendTime = Date.now()
        this.resending = false
        this.checkIdle()
        this.code = ''
        this.$emit('showSuccess', 'We have sent you a new link.')
      })
    },
  },
  beforeUnmount () {
    this.stopCodeInterval()
  },
}
</script>

<style scoped>
ion-button {
  --padding-start: 2em;
  --padding-end: 2em;
  height: 2em;
  --border-radius: 20px;
  --background: var(--ion-color-primary-contrast);
  --background-focused: var(--ion-color-primary-contrast);
  --background-activated: var(--ion-color-primary-contrast);
  --background-hover: var(--ion-color-primary-contrast);
  --color-focused: var(--ion-color-primary-shade);
  --color-activated: var(--ion-color-primary-shade);
  --color-hover: var(--ion-color-primary-shade);
  --color: var(--ion-color-primary-shade);
  margin-bottom: 7px;
  margin-top: 10px;
}
ion-spinner {
  color: var(--ion-color-primary-shade);
}
ion-item {
  --border-style: none;
  text-align: center;
  --background: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
  --highlight-color-focused: transparent;
}
.otp-input-container {
  font-family: 'Source Sans Pro';
  font-size: 50px;
  text-align: left;
  letter-spacing: 0.4em;
  caret-color: transparent;
  width: 5.4em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(-1.2em - 15px);
}
.otp-input {
  --padding-top: 10px;
  --padding-end: 0;
  --padding-bottom: 10px;
  --padding-start: 8px;
  --ion-font-family: 'Source Sans Pro';
}
.otp-mask {
  display: inline-block;
  position: relative;
  bottom: calc(1.2em + 15px);
  left: calc(-0.2em + 8px);
  width: 0.85em;
  margin-right: 0.05em;
  height: 1.2em;
  border: 3px solid #d9e1e6;
  border-radius: 10px;
  background: var(--ion-color-primary);
}
@media only screen and (max-width: 455px) {
  .otp-input-container {
    font-size: max(11vw, 30px);
  }
  .otp-mask {
    border: 2px solid #d9e1e6;
    border-radius: 8px;
  }
}
</style>

<style>
.otp-input input {
  width: 6.5em !important;
  max-width: 6.5em !important;
}
</style>
